var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-wrap no-wrap-css"},[_c('div',{ref:"table-scroll",staticClass:"table-scroll"},[_c('table',{staticClass:"tbl tbl-export",class:_vm.css},[(_vm.rows.length)?_c('thead',[_c('tr',[(_vm.rowsCollapsible)?_c('th',{staticClass:"short-column sticky-column-header subRowCollapsible"}):_vm._e(),_vm._l((_vm.headers),function(header,key,i){return _c('th',{key:i,ref:((header.name) + "_" + i),refInFor:true,class:[header.isSticky ? 'sticky-column sticky-column-header' : ''],style:(_vm.colWidthStyle(header)),on:{"mouseover":function (el) { return _vm.handleMouseOver(el, header.name, ((header.name) + "_" + i)); },"mouseleave":_vm.handleMouseLeave}},[_vm._v(" "+_vm._s(header.name)+" ")])})],2)]):_vm._e(),_c('tbody',{staticClass:"body-half-screen"},_vm._l((_vm.rows),function(item,i){return _c('tr',{key:i,class:[
            { 'sub-row': !!item.subRow },
            ("" + (item.open ? 'highlightBackgroundParent' : '')) ],attrs:{"id":i}},[(_vm.rowsCollapsible)?_c('td',{class:[
              item.subRow ? 'sub-row' : '',
              'short-column sticky-column subRowCollapsible',
              item.open && !item.subRowLoading ? 'highlightBorder' : '' ]},[_c('div',{staticClass:"relative"},[(item.open && !item.subRowLoading)?_c('div',{staticClass:"chevron-arrow-border"}):_vm._e(),(item.timesExposed > 1 && !item.subRow && !item.subRowLoading)?_c('font-awesome-icon',{staticClass:"pointer",attrs:{"icon":['fa', item.open ? 'chevron-down' : 'chevron-right']},on:{"click":function($event){return _vm.$emit('row-click', { data: item, index: i })}}}):_vm._e(),(item.open && item.subRowLoading)?_c('b-loading-spinner',{staticClass:"touches-loader"}):_vm._e()],1)]):_vm._e(),_vm._l((_vm.headers),function(header,key,j){return _c('td',{key:j,ref:((_vm.readVal(item, header)) + "_" + i + "_" + (header.name)),refInFor:true,staticClass:"tooltip_data",class:[
              header.isSticky ? 'sticky-column' : '',
              !!item.subRow ? 'sub-row-column-highlight' : '' ],style:(_vm.colWidthStyle(header)),on:{"mouseover":function (el) { return _vm.handleMouseOver(
                  el,
                  _vm.getItemTooltipVal(item, header),
                  ((_vm.readVal(item, header)) + "_" + i + "_" + (header.name)),
                  true
                ); },"mouseleave":_vm.handleMouseLeave}},[_vm._v(" "+_vm._s(_vm.readVal(item, header))+" ")])})],2)}),0)])]),_c('div',{staticClass:"tooltipText",style:(_vm.activeEl.style)},[_vm._v(" "+_vm._s(_vm.activeEl.text)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }